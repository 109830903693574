<template>
  <div class="row" v-if="service_provider">
    <div class="col-12">
      <div class="title-nav">
        <h2>{{ $t("Server_Provider") }}</h2>
        <div class="d-flex align-items-center">
        </div>
      </div>
      <div class="col-12 mb-5">
        <div class="provider">
          <img :src="service_provider.image" alt="" />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="item" v-if="service_provider.is_etmaam != 1">
        <div class="title">{{ $t("FullName") }}</div>
        <div class="value">{{ branch.name }}</div>
      </div>
      <div class="item" v-else>
        <div class="title">{{ $t("CompanyName") }}</div>
        <div class="value">{{ $t("Etmaam For Services") }}</div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="item">
        <div class="title">{{ $t("Branch Phone") }}</div>
        <div class="value">{{ branch.phone_number }}</div>
      </div>
    </div>
    <div class="col-md-6" v-if="employee">
      <div class="item">
        <div class="title">{{ $t("Employee Name") }}</div>
        <div class="value">{{ employee.name}}</div>
      </div>
    </div>
    <div class="col-md-6" v-if="employee">
      <div class="item">
        <div class="title">{{ $t("Employee Phone") }}</div>
        <div class="value">{{ employee.phone }}</div>
      </div>
    </div>
    <div class="col-md-6" v-if="service_provider.is_etmaam != 1">
      <div class="item">
        <div class="title">{{ $t("Branche") }}</div>
        <div class="value">{{ branch.location }}</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="title-nav">
        <h2>{{ $t("Service_requester") }}</h2>
        <div class="d-flex align-items-center">
          <Show_Price v-if="OrderData?.status?.id == 6" :price_offers="OrderData?.price_offers"/>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="item">
        <div class="title">{{ $t("FullName") }}</div>
        <div class="value">{{ User.name }}</div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="item">
        <div class="title">{{ $t("CompanyName") }}</div>
        <div class="value">
          <span style="direction: ltr; display: inline-block">{{Company.name }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="item">
        <div class="title">{{ $t("User Phone") }}</div>
        <div class="value">
          <span style="direction: ltr; display: inline-block">{{User.phone }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="item">
        <div class="title">{{ $t("Email") }}</div>
        <div class="value">{{ User.email }}</div>
      </div>
    </div>
    <div class="col-md-6" v-if="User.city != null">
      <div class="item">
        <div class="title">{{ $t("Adress") }}</div>
        <div class="value">{{ User.city?.name[this.$i18n.locale] }}</div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="item">
        <div class="title">{{ $t("Tax_Number") }}</div>
        <div class="value">{{ Company.tax_number }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookie from "cookie-universal";
const cookie = Cookie();
export default {
  data() {
    return {
      User: cookie.get("Userdata"),
      Company: cookie.get("Companydata"),
      lang: cookie.get("lang"),
    };
  },
  props: {
    service_provider: Object,
    branch: Object,
    employee: Object,
  },
};
</script>
